@import "//fonts.chrissx.de/";
html {
  color: #fff;
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  background-color: #000;
  border-color: #fff;
}

html, html * {
  font-family: Unifont, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.important {
  color: red;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

td, th {
  text-align: left;
  white-space: nowrap;
  border: 1px solid #202020;
  padding: 4pt 2pt 4pt 4pt;
  overflow: scroll;
}

.unimportant {
  color: silver;
}

img {
  background-color: #fff;
  width: 384px;
  height: 260px;
}

figcaption, figcaption * {
  color: gray;
  text-align: center;
  width: 384px;
  font-size: 11pt;
}

.buttonhost {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.interbuttonspacer {
  width: 32pt;
}
/*# sourceMappingURL=index.1e5587b9.css.map */
