@import url(//fonts.chrissx.de/);

html {
    color: white;
    background-color: black;
    border-color: white;
    color-scheme: dark;
}

html, html * {
    font-family: Unifont, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.important {
    color: red;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

td, th {
    border: 1px solid #202020;
    text-align: left;
    padding: 4pt 2pt 4pt 4pt;
    overflow: scroll;
    white-space: nowrap;
}

.unimportant {
    color: #c0c0c0;
}

img {
    background-color: white;
    width: 384;
    height: 260;
}

figcaption, figcaption * {
    color: #808080;
    font-size: 11pt;
    text-align: center;
    width: 384;
}

.buttonhost {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
}

.interbuttonspacer {
    width: 32pt;
}
